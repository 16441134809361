import { render, staticRenderFns } from "./superAdmin.html?vue&type=template&id=4c57263c&scoped=true&external"
import script from "./superAdmin.ts?vue&type=script&lang=ts&external"
export * from "./superAdmin.ts?vue&type=script&lang=ts&external"
import style0 from "./superAdmin.scss?vue&type=style&index=0&id=4c57263c&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c57263c",
  null
  
)

/* custom blocks */
import block0 from "./superAdmin.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2FsuperAdmin%2FsuperAdmin.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabs})
